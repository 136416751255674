import { Router, NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Pdv } from '../../../../../core/models/pdv';
import { PdvService } from '../../../../services/pdv.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdv-list',
  templateUrl: './pdv-list.component.html',
  styleUrls: ['./pdv-list.component.scss'],
})
export class PdvListComponent implements OnInit {


  allPdv:Pdv[] = [];
  enabledList:Pdv[] = [];
  filteredPdv:Pdv[] = [];
  @Input() mode = '';



  constructor(private pdvService: PdvService, private router: Router) { 
    
    
   
  }

  getPdvList(mode = ''){
      console.log('PDV lis mode', mode)
      return (mode === 'pnp') ? this.pdvService.listPnpPdv() : this.pdvService.listPdv()
  }


  ngOnInit() {
   //this.pdvService.listPdv()
   this.getPdvList(this.mode)
   .subscribe( (pdvs:Pdv[])=>{
     this.allPdv = pdvs;
     this.enabledList = pdvs.filter((pdv: Pdv) => pdv.enabled  );
   }, err => console.warn(err));

  }


  filterResults(evt): void {
    const searchTerm = evt.srcElement.value;
    console.log('hai cercato', searchTerm)
    if(searchTerm && searchTerm.length > 3){
      //ricerca sempliciotta
      this.filteredPdv = this.allPdv.filter((pdv:Pdv) => JSON.stringify(pdv).toLowerCase().includes(searchTerm.toLowerCase()) );
      console.table(this.filteredPdv)        
    }else{
      this.filteredPdv = [];
    }
	}

  openPdv(pdv:Pdv){
    const navigationExtras: NavigationExtras = {
      state: {
        pdv
      }
    };

    this.router.navigate([`pdv-detail/${pdv.id}`], navigationExtras);
  }

}
