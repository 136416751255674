import { PdvEnabledItemComponent } from '../pdv-enabled-item/pdv-enabled-item.component';
import { Component, OnInit } from '@angular/core';
import { PdvService } from 'src/app/admin/services/pdv.service';

@Component({
  selector: 'app-pdv-search-item',
  templateUrl: './pdv-search-item.component.html',
  styleUrls: ['./pdv-search-item.component.scss'],
})
export class PdvSearchItemComponent extends PdvEnabledItemComponent{

  constructor(public pdvService: PdvService) { 
    super(pdvService)
  }
}
