import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams} from '@ionic/angular';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
    @Input() title: string
    @Input() message: string
    @Input() submitText: string
    @Input() cancelText: string
    @Input() withCheckbox: boolean
    @Input() withYesNo: boolean
    @Input() yesText: string
    @Input() noText: string
    private checked: boolean = false
    public action: string = 'cancel'
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }
  yes() {
      this.action = 'yes'
      this.dismiss()
  }
  no() {
    this.action = 'no'
    this.dismiss()
  }
  continue() {
      this.action = 'continue'
      this.dismiss()
  }
  cancel() {
      this.action = 'cancel'
      this.dismiss()
  }
  dismiss() {
    this.modalController.dismiss({
        'checkboxed' : this.checked,
        'action': this.action
      });
  }
  setCheckboxValue(e): void {
    this.checked = e.currentTarget.checked;	
  }
}
