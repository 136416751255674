import { Observable } from 'rxjs';
import { CategoriesHttpService } from './http/categories-http.service';
import { Injectable } from '@angular/core';
import { CategorySetting, SoglieDefault } from '../models/category-setting';

@Injectable({
  providedIn: 'root'
})
export class AlertSettingService {

  constructor(private categoriesHttpService: CategoriesHttpService) { }

  listCategoriesSettings():Observable<CategorySetting[]>{
      return this.categoriesHttpService.listCategoriesSettings();    
  }

  saveSettings(settings: CategorySetting[]){
    return this.categoriesHttpService.updateCategories(settings);
  }

  resetSettings(settings: CategorySetting[]){
    //Fixme, copiare il valore per non fare una modifica byRef
    settings.forEach( (row, index) => {
        //search for default value
        const found = SoglieDefault.filter( (s) => (s.famcod === row.famcod));
        if(found.length > 0){
          const defaultRow = found[0];
          row.alertOne = defaultRow.alertTwo;
          row.alertTwo = defaultRow.alertOne;
          row.alertOos = defaultRow.alertOos;
        }else{
          console.log(`${row.famcod} not found in default alerts`)
        }
    })
    return settings;

  }

  resetLimits(settings: CategorySetting[]){
    settings.forEach( (row, index) => {
      row.threshold = 30;
    })
    return settings;
  }
}
