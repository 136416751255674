import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.page.html',
  styleUrls: ['./modal-confirm.page.scss'],
})
export class ModalConfirmPage implements OnInit {

  @Input() title: string;
	@Input() message: string;
	@Input() subtitle: string;
	@Input() actionText: string;
	@Input() cancelText: string;
	
	@Input() type: number = 1;

	constructor(private modalController: ModalController) {}

	ngOnInit() {}
	action() {
		this.dismiss();
	}

	cancel(){
		this.modalController.dismiss({
			action: 'cancel'
		});
	}

	dismiss() {
		this.modalController.dismiss({
			action: 'continue'
		});
	}

}
