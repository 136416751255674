import {StartupGuardGuard} from './guards/startup-guard.guard';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

// startup(){
//   if(window['cordova']){
//       return  {
//         path: '',
//         loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
//       };
//   } else{
//     return {
//       path: '',
//       loadChildren: () => import('./admin/pages/login/login.module').then( m => m.LoginPageModule)
//     };
//   }
// }

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        // loadChildren: () => import('./admin/pages/login/login.module').then( m => m.LoginPageModule)
        canActivate: [StartupGuardGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'landing',
        loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule)
    },
    {
        path: 'expire-manager',
        loadChildren: () => import('./pages/expire-manager/expire-manager.module').then(m => m.ExpireManagerModule)
    },

    {
        path: 'splashscreen',
        loadChildren: () => import('./pages/splashscreen/splashscreen.module').then(m => m.SplashscreenPageModule)
    },
    {
        path: 'resolve-alert',
        loadChildren: () => import('./pages/resolve-alert/resolve-alert.module').then(m => m.ResolveAlertPageModule)
    },
    {
        path: 'out-of-stock',
        loadChildren: () => import('./pages/out-of-stock/out-of-stock.module').then(m => m.OutOfStockPageModule)
    },
    {
        path: 'mission/:id',
        loadChildren: () => import('./pages/mission/mission.module').then(m => m.MissionPageModule)
    },
    {
        path: 'manager',
        loadChildren: () => import('./pages/manager/manager.module').then(m => m.ManagerPageModule)
    },
    {
        path: 'alert/:id',
        loadChildren: () => import('./pages/alert/alert.module').then(m => m.AlertPageModule)
    },
    {
        path: 'new-alert/:id',
        loadChildren: () => import('./pages/new-alert/new-alert.module').then(m => m.NewAlertPageModule)
    },
    {
        path: 'barcode',
        loadChildren: () => import('./pages/barcode/barcode.module').then(m => m.BarcodePageModule)
    },
    {
        path: 'update-alert/:id',
        loadChildren: () => import('./pages/update-alert/update-alert.module').then(m => m.UpdateAlertPageModule)
    },
    {
        path: 'admin-login',
        loadChildren: () => import('./admin/pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
    },
    {
        path: 'pdv-detail/:id',
        loadChildren: () => import('./admin/pages/pdv-detail/pdv-detail.module').then(m => m.PdvDetailPageModule)
    },
    {
        path: 'modal-confirm',
        loadChildren: () => import('./admin/pages/modals/modal-confirm/modal-confirm.module').then(m => m.ModalConfirmPageModule)
    },
    {
        path: 'pdv-dashboard',
        loadChildren: () => import('./admin/pages/manager/manager.module').then(m => m.ManagerPageModule)
    },
    {
        path: 'user/checkauth',
        loadChildren: () => import('./user-checkauth/user-checkauth.module').then(m => m.UserCheckauthPageModule)
    },
    {
        path: 'employee-list',
        loadChildren: () => import('./pages/employee-list/employee-list.module').then(m => m.EmployeeListPageModule)
    },
    {
        path: 'alerts-filter-options',
        loadChildren: () => import('./shared/modals/alerts-filter-options/alerts-filter-options.module')
            .then(m => m.AlertsFilterOptionsPageModule)
    },
    {
        path: 'laser-scanner',
        loadChildren: () => import('./pages/laser-scanner/laser-scanner.module').then(m => m.LaserScannerPageModule)
    },
    // Pick Pack Routes
    {
        path: 'time-slot-list',
        loadChildren: () => import('./pages/time-slot-list/time-slot-list.module').then(m => m.TimeSlotListPageModule)
    },
    {
        path: 'order-list',
        loadChildren: () => import('./pages/order-list/order-list.module').then(m => m.OrderListPageModule)
    },
    {
        path: 'order-detail',
        loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
    },  {
    path: 'pick-pack-tutorial',
    loadChildren: () => import('./pages/pick-pack-tutorial/pick-pack-tutorial.module').then( m => m.PickPackTutorialPageModule)
  },
  {
    path: 'employee-list-pnp',
    loadChildren: () => import('./pages/employee-list-pnp/employee-list-pnp.module').then( m => m.EmployeeListPnpPageModule)
  },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
