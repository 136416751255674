import { environment as env } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Pdv, PdvEnableUsersModel } from '../../../core/models/pdv';
@Injectable({
  providedIn: 'root'
})
export class PdvPNPHttpService {

  
  constructor(private http:HttpClient) { }

   listPdv(): Observable<any>{
     return this.http.get(env.admin_pnp_pdvList);
   }

   getPdv(id:string): Observable<any>{
    return this.http.get(`${env.admin_pnp_pdvDetail}?id=${id}`);
  }

  //TODO getPdv by identity

   updatePdv(pdv: Pdv): Observable<any>{
    return this.http.post(env.admin_pnp_pdvUpdate, pdv);
  }

  enableUsers(payload: PdvEnableUsersModel){
    return this.http.post(env.admin_pnp_pdvEnableUsers, payload);
  }

  updateFlags(pdv: Pdv){
    return this.http.post(env.admin_pnp_pdvUpdateFlags, pdv);
  }
}