import {AuthService} from '../auth.service';
import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpHeaders,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError, Subject, of} from 'rxjs';
import {tap, catchError, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BASE_URL_PICK_PACK, environment as env} from '../../../environments/environment';

/**
 * Modifica le chiamate REST appendendo l'Header Authorization necessari all'autenticazione con i servizi
 */
@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

    private cachedRequests: Array<HttpRequest<any>> = []
    
    public refreshTokenInProgress = 0;
    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    addHeaders(request) {
        if (this.shouldAppendJWT(request.url)) {
            let custHeaders = new HttpHeaders({
                Accept: '*/*',
                'Content-Type': 'application/json'
            });

            // custHeaders = custHeaders.set(
            //     "x-apikey", env.APIGEE_APIKEY
            // ).set("sessionid", this.authService.deviceInfo.udid);

            if (this.shouldAppendJWT(request.url)) {
                const jwt = this.authService.getJWT();
                if (jwt) {
                    custHeaders = custHeaders.set('Authorization', `Bearer ${jwt}`);
                }
            }
            if (this.shouldAppendApiKey(request.url)) {
                custHeaders = custHeaders.set('x-apikey', env.api_key).set('origin','*')
            }

            request = request.clone({
                headers: custHeaders
            });
        }
        request.headers.append('Accept', 'application/json');
       
        return request;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addHeaders(request);
        console.log('[INTERCEPT]')

        // controlla se c'è errore nella chiamata, nel caso intercetta
        return next.handle(request).pipe(
            catchError(error => {
                    return this.handleResponseError(error, request, next);
                }
            ));

    }

    /**
     * se l'errore è 401, fa partire un refresh del token
     *
     * @private
     * @param {*} error
     * @param {*} [request]
     * @param {*} [next]
     * @returns {Observable<any>}
     * @memberof JwtInterceptor
     */
    private handleResponseError(error, request?, next?): Observable<any> {
        if (error instanceof HttpErrorResponse) {

            // se errore durante refresh
            if (error.url.startsWith(env.sso_refreshtoken)) {
                this.logoutC4User()
                return
            }

            // 
            if (error.status !== 401) {
                //ritrasmetto qualsiasi errore diverso da 401
                return throwError(error);
            }

            return this.refreshToken().pipe(
                switchMap(() => {
                    request = this.addHeaders(request);
                    return next.handle(request);
                }),
                catchError(e => { // se fallisce refresh fai logout
                    // FAI LOGOUT


                    this.logoutC4User()
                    return of(false)
                    // if (e.status !== 401) {
                    //     return this.handleResponseError(e);
                    // } else {
                    //     // FAI LOGOUT
                    //     this.authService.removeAuthFromStorage()
                    //     this.router.navigate([''], {replaceUrl: true})
                    // }
                }));

        } else {
            console.log('handleResponseError ALTRO ERRORE');

        }
    }

    /** Determina su quali request appendere l'header Authorization
     *
     */
    private shouldAppendJWT(url: string): boolean {
        return url.includes('/api/');
    }

    /**
     *
     *
     * @private
     * @returns {Observable<any>}
     * @memberof JwtInterceptor
     */
    private refreshToken(): Observable<any> {
        const currTime = new Date().getTime();
        if(currTime - this.refreshTokenInProgress < 20000){
            console.log(`Sono passati meno di 20 secondi dall'ultima chiamata al refresh`)
        //if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {

            //this.refreshTokenInProgress = true;
            this.refreshTokenInProgress = new Date().getTime();

            return this.authService.refreshToken().pipe(
                tap((response) => {
                    console.log('response');
                    console.log(response);

                    this.authService.auth = response;
                    localStorage.setItem('auth', JSON.stringify(response));

                    //this.refreshTokenInProgress = false;
                    this.refreshTokenInProgress = 0;
                    this.tokenRefreshedSource.next();
                }),
                catchError(() => {
                    console.log("refreshToken catchError");
                    this.refreshTokenInProgress = 0;
                    //this.refreshTokenInProgress = false;

                    this.logoutC4User()
                    // this.logout();
                    return of(false)
                }));
        }
    }


    logoutC4User() {
        this.authService.removeAuthFromStorage()
        this.router.navigate([''], {replaceUrl: true}).then(r => {
        })
    }

    private shouldAppendApiKey(url: string) {           
        return url.includes(BASE_URL_PICK_PACK) && env.api_key != "";
    }
}
