import { PdvPNPHttpService } from './http/pdv-pnphttp.service';
import { PdvEnableUsersModel } from './../../core/models/pdv';
import { Pdv } from '../../core/models/pdv';
import { Observable } from 'rxjs';
import { PdvHttpService } from './http/pdv-http.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PdvService {

  constructor(private pdvHttpService: PdvHttpService, private pdvPnpHttpService:PdvPNPHttpService) { 
  }

  listPdv() : Observable<any> {
    return this.pdvHttpService.listPdv();
  }
  updatePdv(pdv: Pdv) : Observable<any> {
    return this.pdvHttpService.updateFlags(pdv);
  }

  saveUsers(pdv: Pdv) : Observable<any> {



    const payload = {
      id:pdv.id,
      enabled_users: pdv.users.filter((user) =>  user.enabled).map( (u) => (u.username))
    }

    console.log('Payload forn enable users', payload);
    
    return this.pdvHttpService.enableUsers(payload);
  }

  getPdv(id:string): Observable<Pdv>{
    return this.pdvHttpService.getPdv(id);
  }

  getStoreIconFromTemplate(insegna: string){
    if(!insegna) {
      return '';
    }
    switch(insegna.toLowerCase()){
      case 'carrefour market':
          return '/assets/icons/markets/carrefour-market-logo.svg';
      case 'carrefour express':
          return '/assets/icons/markets/carrefour-express-logo.svg';
      default:
        return '/assets/icons/markets/carrefour-logo.svg';
    }

}


// PNP clone methods

listPnpPdv() : Observable<any> {
  return this.pdvPnpHttpService.listPdv();
}
updatePnpPdv(pdv: Pdv) : Observable<any> {
  return this.pdvPnpHttpService.updateFlags(pdv);
}

savePnpUsers(pdv: Pdv) : Observable<any> {
  const payload = {
    id:pdv.id,
    enabled_users: pdv.users.filter((user) =>  user.enabled).map( (u) => (u.username))
  }

  console.log('Payload forn enable users', payload);
  
  return this.pdvPnpHttpService.enableUsers(payload);
}

getPnpPdv(id:string): Observable<Pdv>{
  return this.pdvPnpHttpService.getPdv(id);
}

}
