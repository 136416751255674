import { PnpUsersComponent } from './pages/manager/components/pnp-users/pnp-users.component';
import { SettingsComponent } from './pages/manager/components/settings/settings.component';
import { AlertsListComponent } from './pages/manager/components/alerts-list/alerts-list.component';
import { ModalConfirmPageModule } from './pages/modals/modal-confirm/modal-confirm.module';
import { ModalPageModule } from './../shared/modal/modal.module';

import { AlertDropdownListComponent } from './pages/dashboard/components/alert-dropdown-list/alert-dropdown-list.component';
import { AlertAdminComponent } from './pages/dashboard/components/alert-admin/alert-admin.component';
import { PdvSearchItemComponent } from './pages/dashboard/components/pdv-search-item/pdv-search-item.component';
import { FormsModule } from '@angular/forms';
import { PdvEnabledItemComponent } from './pages/dashboard/components/pdv-enabled-item/pdv-enabled-item.component';
import { PdvListComponent } from './pages/dashboard/components/pdv-list/pdv-list.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


const components = [
  PdvListComponent,
  PdvEnabledItemComponent,
  PdvSearchItemComponent,  
  AlertAdminComponent,
  AlertDropdownListComponent,
  AlertsListComponent,
  SettingsComponent,
  PnpUsersComponent

]


@NgModule({
  declarations: [
    ...components
  ],
  exports:[
    ...components
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ModalPageModule,
    ModalConfirmPageModule
       
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AdminModule { }
