import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertSettingService } from 'src/app/admin/services/alert-setting.service';
import { CategorySetting } from 'src/app/admin/models/category-setting';

@Component({
  selector: 'app-alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss'],
})
export class AlertsListComponent implements OnInit {
  isLoading = true;
  settings:CategorySetting[] = [];

  constructor(private modalController: ModalController, private alertSettingService: AlertSettingService) { 
    //caricamento settings
    this.reloadData();
   

  }

  reloadData(){
    this.isLoading = true;
    this.alertSettingService.listCategoriesSettings().subscribe( (allSettings:CategorySetting[])=> {
      this.isLoading = false;
      this.settings = allSettings;

    }, err => {
      this.isLoading = false;
      console.warn(err);
    })
  }
  ngOnInit() {}

}
