import { environment as env} from './../../../environments/environment';
import { C4Auth } from '../../core/models/c4auth';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { PdvUser } from 'src/app/core/models/pdv';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {

  constructor(private http: HttpClient) {
    
   }

  public checkAuth(code: string): Observable<C4Auth>{
    return this.http.get<C4Auth>(`${env.sso_checkcode}${code}`);
  }

  public me(): Observable<PdvUser>{
    return this.http.get<PdvUser>(`${env.me}`);
  }

  public mePickPack(): Observable<PdvUser>{
    return this.http.get<PdvUser>(`${env.me_pick_pack}`);
  }

  /**
   *
   *
   * @param {string} refresh_token
   * @returns {Observable<C4Auth>}
   * @memberof AuthHttpService
   */
  public refreshToken(refresh_token: string): Observable<C4Auth> {
      return this.http.get<C4Auth>(`${env.sso_refreshtoken}${refresh_token}`);
  }
}
