import { ModalConfirmPage } from './../../../modals/modal-confirm/modal-confirm.page';
import { OverlayEventDetail, ModalOptions } from '@ionic/core';
import { CategorySetting } from './../../../../models/category-setting';
import { AlertSettingService } from './../../../../services/alert-setting.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalPage } from 'src/app/shared/modal/modal.page';

@Component({
	selector: 'app-alert-admin',
	templateUrl: './alert-admin.component.html',
	styleUrls: ['./alert-admin.component.scss']
})
export class AlertAdminComponent implements OnInit {
	isLoading = true;

	settings: CategorySetting[] = [];
	constructor(private modalController: ModalController, private alertSettingService: AlertSettingService) {
		//caricamento settings
		this.reloadData();
	}

	reloadData() {
		this.isLoading = true;
		this.alertSettingService.listCategoriesSettings().subscribe(
			(allSettings: CategorySetting[]) => {
				this.isLoading = false;
				this.settings = allSettings;
			},
			(err) => {
				this.isLoading = false;
				console.warn(err);
			}
		);
	}

	ngOnInit() {}

	save() {
		console.table(this.settings);
		this.alertSettingService.saveSettings(this.settings).subscribe(
			(result: any) => {
				console.log('Impostazioni aggiornate', result);

				this.presentModalAlertSaved();
			},
			(err) => console.error(err)
		);
	}

	async resetSoglie() {
		const modalOptions: ModalOptions = {
			component: ModalConfirmPage,
			componentProps: {
				type: 2,
				title: 'Reset Soglie',
				subtile: 'I dati inseriti saranno azzerati',
				message: 'Vuoi resettare le soglie reimpostando i valori di default?',
				actionText: 'Conferma',
				cancelText: 'Annulla'
			},
			showBackdrop: true,
			backdropDismiss: false,
			cssClass: ['c4-alertsaved-modal']
		};

		const modal = await this.modalController.create(modalOptions);

		modal.onDidDismiss().then((eventDetail: OverlayEventDetail) => {
			const data = eventDetail.data;
			if (data.action === 'continue') {
				this.settings = this.alertSettingService.resetSettings(this.settings);
				this.save();
			}
		});
		return await modal.present();
	}

	async resetLimiti() {		
		const modalOptions: ModalOptions = {
			component: ModalConfirmPage,
			componentProps: {
				type: 2,
				title: 'Reset Limiti',
				subtile: 'I dati inseriti saranno azzerati',
				message: 'Vuoi resettare il valore dei limiti reimpostando i valori di default (30g)?',
				actionText: 'Conferma',
				cancelText: 'Annulla'
			},
			showBackdrop: true,
			backdropDismiss: false,
			cssClass: ['c4-alertsaved-modal']
		};

		const modal = await this.modalController.create(modalOptions);

		modal.onDidDismiss().then((eventDetail: OverlayEventDetail) => {
			const data = eventDetail.data;
			if (data.action === 'continue') {
				this.settings = this.alertSettingService.resetLimits(this.settings);
				this.save();
			}
		});
		return await modal.present();
	}

	async presentModalAlertSaved() {
		const modalOptions: ModalOptions = {
			component: ModalConfirmPage,
			componentProps: {
				title: 'Modifiche salvate',
				actionText: 'Ho capito'
			},
			showBackdrop: true,
			backdropDismiss: false,
			cssClass: ['c4-alertsaved-modal']
		};

		const modal = await this.modalController.create(modalOptions);

		modal.onDidDismiss().then((eventDetail: OverlayEventDetail) => {
			this.modalController.dismiss();
		});

		return await modal.present();
	}
}
