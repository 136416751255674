import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartupGuardGuard implements CanActivate {
  constructor(private router: Router, private platform: Platform, private device: Device) { 


  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log('Controllo piattaforma')
      if(this.device.platform==="browser"){
        this.router.navigateByUrl('admin-login');
          return false;
      }
    return true;
  }
  
}
