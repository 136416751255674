
import { environment as env } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Pdv, PdvEnableUsersModel } from '../../../core/models/pdv';

@Injectable({
  providedIn: 'root'
})
export class PdvHttpService {

  constructor(private http:HttpClient) { }

   listPdv(): Observable<any>{
     return this.http.get(env.admin_pdvList);
   }

   getPdv(id:string): Observable<any>{
    return this.http.get(`${env.admin_pdvDetail}?id=${id}`);
  }

  //TODO getPdv by identity

   updatePdv(pdv: Pdv): Observable<any>{
    return this.http.post(env.admin_pdvUpdate, pdv);
  }

  enableUsers(payload: PdvEnableUsersModel){
    return this.http.post(env.admin_pdvEnableUsers, payload);
  }

  updateFlags(pdv: Pdv){
    return this.http.post(env.admin_pdvUpdateFlags, pdv);
  }
}
