import { PdvService } from '../../../../services/pdv.service';
import { Pdv } from '../../../../../core/models/pdv';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pdv-enabled-item',
  templateUrl: './pdv-enabled-item.component.html',
  styleUrls: ['./pdv-enabled-item.component.scss'],
})
export class PdvEnabledItemComponent implements OnInit {

  @Input() pdv: Pdv

  constructor(public pdvService: PdvService) { }

  ngOnInit() {}

  

}
