// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// DEV
// export const BASE_URL = `https://carrefour.softfobia.eu`;
// export const BASE_URL_PICK_PACK = `https://c4-pap.softfobia.eu`; // Remoto
// export const CHECKOUT_URL = `http://scadenzepdv.carrefour.it/user/checkauth`
// export const API_KEY = "";

// STAGE
 export const BASE_URL = `https://carrefour-italia-nonprod-test.apigee.net/shelf-life`
 export const BASE_URL_PICK_PACK = `https://carrefour-italia-nonprod-test.apigee.net/pnp-order`
 export const CHECKOUT_URL = `https://shelflife-test.carrefour.it/user/checkauth`
 export const API_KEY = "MdZ23DtRtsHMquqdKE8SKc5yjdt7mPxx";

// PROD
// export const BASE_URL = `https://api.carrefour.it/shelf-life`;
// export const BASE_URL_PICK_PACK = `https://api.carrefour.it/pnp-order`
// export const CHECKOUT_URL = `https://shelflife.carrefour.it/user/checkauth`
// export const API_KEY = "g4zKVf8EsT6sifUc9OIW7spshSnLQ3fQ";

export const environment = {
    production: false,
    version: { v:'2.0.16 prod', info:'Ambiente prod' },
	//intervallo di download in minuti
	alerts_download_interval:10,    
	timeout: 20000,
    sso: `${BASE_URL}/openam/authorize`,
    sso_checkcode: `${BASE_URL}/openam/checkauth?code=`,
	sso_refreshtoken: `${BASE_URL}/openam/refresh?refresh_token=`,
    me: `${BASE_URL}/api/openam/me`,
    me_pick_pack: `${BASE_URL_PICK_PACK}/api/openam/me`,
    checkauth_url: `${CHECKOUT_URL}`,
    //checkauth_url: `https://shelflife-test.carrefour.it/user/checkauth`,

    catalog_lastVersion: `${BASE_URL}/api/catalog/last-version`,
    setup_start: `${BASE_URL}/api/missions/startSetup`,
    setup_finish: `${BASE_URL}/api/missions/finishSetup`,

    oos_list: `${BASE_URL}/api/missions/oos-list`,
    missions_list: `${BASE_URL}/api/missions/list`,
    missions_finish: `${BASE_URL}/api/missions/finish`,
    mission_start: `${BASE_URL}/api/missions/start`,
    users_missions: `${BASE_URL}/api/missions/users-and-missions`,
    all_alerts:`${BASE_URL}/api/missions/list-all-for-pdv`,

    // PickPack
    orders_by_time_slot: `${BASE_URL_PICK_PACK}/api/orders/list-by-time-slot`,
    orders_overdue_by_time_slot: `${BASE_URL_PICK_PACK}/api/orders/list-overdue`,
    orders_start_order: `${BASE_URL_PICK_PACK}/api/orders/start-orders`,
    orders_confirm_order: `${BASE_URL_PICK_PACK}/api/orders/confirm-order`,
    orders_order_line_item_detail: `${BASE_URL_PICK_PACK}/api/orders/order-line-details`,
    orders_order_line_item_detail_substitute: `${BASE_URL_PICK_PACK}/api/orders/order-line-substitutions`,    
    orders_by_user: `${BASE_URL_PICK_PACK}/api/orders/orders-by-user`,
    free_substitution: `${BASE_URL_PICK_PACK}/api/orders/free-substitution`,
    api_key: `${API_KEY}`,

    // admin
    admin_pdvList: `${BASE_URL}/api/pdv/list`,
    admin_pdvDetail: `${BASE_URL}/api/pdv/details`,
    admin_pdvUpdate: `${BASE_URL}/api/pdv/update`,
    admin_pdvEnableUsers: `${BASE_URL}/api/pdv/enable_users`,
    admin_pdvUpdateFlags: `${BASE_URL}/api/pdv/update_flags`,
    admin_categoryList: `${BASE_URL}/api/catalog/categories-list`,
    admin_categoryUpdate: `${BASE_URL}/api/catalog/categories-update`,

    // admin pnp
    admin_pnp_pdvList: `${BASE_URL_PICK_PACK}/api/pdv/list`,
    admin_pnp_pdvDetail: `${BASE_URL_PICK_PACK}/api/pdv/details`,
    admin_pnp_pdvUpdate: `${BASE_URL_PICK_PACK}/api/pdv/update`,
    admin_pnp_pdvEnableUsers: `${BASE_URL_PICK_PACK}/api/pdv/enable_users`,
    admin_pnp_pdvUpdateFlags: `${BASE_URL_PICK_PACK}/api/pdv/update_flags`,
    admin_pnp_categoryList: `${BASE_URL_PICK_PACK}/api/catalog/categories-list`,
    admin_pnp_categoryUpdate: `${BASE_URL_PICK_PACK}/api/catalog/categories-update`,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import `zone.js/dist/zone-error`;  // Included with Angular CLI.
