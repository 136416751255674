import { environment } from './../../../../environments/environment';
import { CategorySetting } from './../../models/category-setting';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoriesHttpService {

  constructor(private http:HttpClient) { 
    
  }

  listCategoriesSettings() : Observable<any>{
    return this.http.get(environment.admin_categoryList);
  }

  updateCategories(categories: CategorySetting[]){
    return this.http.post(environment.admin_categoryUpdate, categories);

  }

}
