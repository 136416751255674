export interface CategorySetting {
    famcod: string,
    description: string,
    group: string,
    alertOne: number,
    alertTwo: number,
    alertOos: number,
    threshold: number
}


export const SoglieDefault = [
    {"famcod": "1000", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1001", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1002", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1003", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1004", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1010", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1013", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1014", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1015", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1016", "alertOos": 7,"alertOne": 15,"alertTwo": 1},
    {"famcod": "1250", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1400", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1401", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1402", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1403", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1404", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1405", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1406", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1407", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1408", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1409", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1414", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1415", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1420", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "2322", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "1421", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1422", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1430", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1431", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1432", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1433", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1434", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1435", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1436", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1437", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1438", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1439", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1440", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1441", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1442", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1443", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1444", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1445", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1446", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1447", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1450", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1451", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1452", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1453", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1454", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1455", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1456", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1457", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1459", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1460", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1462", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1464", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1465", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1466", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1467", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1468", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1469", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1470", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1471", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1472", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1473", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1474", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1561", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1475", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1477", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1478", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1480", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1482", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1490", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1491", "alertOos": 7,"alertOne": 15,"alertTwo": 0},
    {"famcod": "1500", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1501", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1502", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1503", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1504", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1505", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1507", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1508", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1510", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1511", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1512", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1513", "alertOos": 3,"alertOne": 5,"alertTwo": 0},
    {"famcod": "1514", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1515", "alertOos": 3,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1516", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1517", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1518", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1520", "alertOos": 3,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1530", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1531", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1532", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1533", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1534", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1535", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1536", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1537", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1540", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1541", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1542", "alertOos": 7,"alertOne": 0,"alertTwo": 0},
    {"famcod": "1550", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1552", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1553", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1554", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1555", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1560", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1562", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1563", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1570", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1572", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1573", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "1580", "alertOos": 3,"alertOne": 2,"alertTwo": 0},
    {"famcod": "2000", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2010", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2020", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2030", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2040", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2050", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2054", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2057", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2212", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2228", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2240", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2241", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2243", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2244", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2246", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2247", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2248", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2249", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2250", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2251", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2260", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2270", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2271", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2272", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2273", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2280", "alertOos": 7,"alertOne": 5,"alertTwo": 0},
    {"famcod": "2320", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2321", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2323", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2330", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2331", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2332", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2414", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2416", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2432", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2434", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2435", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2436", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2442", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2443", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2444", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2447", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2448", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2465", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2466", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2468", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2471", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2473", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2477", "alertOos": 3,"alertOne": 1,"alertTwo": 0},
    {"famcod": "2478", "alertOos": 3,"alertOne": 1,"alertTwo": 0}
    ]
    