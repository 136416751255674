import { CategorySetting } from './../../../../models/category-setting';
import { Component, OnInit, Input } from '@angular/core';

const pics = {
  alertOne: '/assets/icons/home/ico-badge-flag-red.svg',
  alertTwo: '/assets/icons/home/ico-badge-flag-yellow.svg',
  alertOos: '/assets/icons/home/ico-badge-flag-grey.svg',
  threshold: ''
}

@Component({
  selector: 'app-alert-dropdown-list',
  templateUrl: './alert-dropdown-list.component.html',
  styleUrls: ['./alert-dropdown-list.component.scss'],
})
export class AlertDropdownListComponent implements OnInit {
  soglie = [];

  pic = null;
  suffix = 'g';
  constructor() { 
    

  }
  
  @Input() category: CategorySetting;
  @Input() field: string;

  range(from, to, step){
    return Array.from(Array(~~((to - from) / step) + 1)).map(
      (v, k) => from + k * step
  );
  }

  ngOnInit() {    
    switch(this.field){
      case 'threshold':{
        const limiti = [{label: 'Nessuno', value: 0} ];
        this.soglie = limiti.concat( this.range(30,90,10).map((n) => ({label:`${n}`, value : n }) ));        
        break;
      }
      case 'alertOos':{
        this.soglie = this.range(0,90,1).map((n) => ({label:`${n}g`, value : n }) );
        break;
      }
      default:{
        this.soglie = this.range(0,40,1).map((n) => ({label:`${n}g`, value : n }) );
        break;
      }

    }


    this.suffix = (this.field ==='threshold') ? '': 'g'
    this.pic = pics[this.field];

  }

  

}
