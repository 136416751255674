import { Network } from '@ionic-native/network/ngx';
import { AdminModule } from './admin/admin.module';

import { SQLite } from '@ionic-native/sqlite/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FileTransfer} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Zip } from '@ionic-native/zip/ngx';
import { ReactiveFormsModule} from '@angular/forms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { SqliteDbCopy } from '@ionic-native/sqlite-db-copy/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { JwtInterceptor } from './services/interceptors/jwt-interceptor';
import { IonicStorageModule } from '@ionic/storage';
import { DebounceClickDirective } from './directives/debounce-click.directive';

@NgModule({
  declarations: [
    AppComponent,
    // DebounceClickDirective  
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    ShowHidePasswordModule,
    IonicModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AdminModule,
    IonicStorageModule.forRoot({
      name: '__alertsdb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    IonicModule.forRoot({hardwareBackButton: false})

  ],
  providers: [
    Network,
    InAppBrowser,
    Zip,
    File,
    FileTransfer,
    BarcodeScanner,
    SQLite,    
    SqliteDbCopy,
    Device,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },     
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
