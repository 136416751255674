import { catchError } from 'rxjs/operators';
import {Device} from '@ionic-native/device/ngx';
import {C4Auth} from '../core/models/c4auth';
import { Observable, of } from 'rxjs';
import {AuthHttpService} from './http/auth-http.service';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/internal/operators/tap';
import {map} from 'rxjs/internal/operators/map';
import {PdvUser} from '../core/models/pdv';

export enum Role {
    User = 'ROLE_USER',
    Director = 'ROLE_DIRECTOR',
    Admin = 'ROLE_ADMIN',
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public auth: C4Auth = null;
    public expireManagerUser: PdvUser = null;
    public pickPackUser: PdvUser = null;

    constructor(private device: Device, private authHttpService: AuthHttpService) {
        const authStr = sessionStorage.getItem('auth');
        if (authStr) {
            console.info('ripristino auth', authStr);
            this.auth = JSON.parse(authStr);
        }

        const authPPStr = sessionStorage.getItem('pp_user');
        if (authPPStr) {
            console.info('ripristino auth pp', authPPStr);
            this.pickPackUser = JSON.parse(authPPStr);
        }

        const userStr = sessionStorage.getItem('user');
        if (userStr) {
            console.info('ripristino user', userStr);
            this.expireManagerUser = JSON.parse(userStr);
        }
    }

    getJWT() {
        return this.auth ? this.auth.jwt_token : null;
    }

    /**
     *
     *
     * @returns {string}
     * @memberof AuthService
     */
    getRefreshTokenJWT(): string {
        return this.auth ? this.auth.refresh_token : null;
    }

    checkAuth(code: string): Observable<C4Auth> {
        return this.authHttpService.checkAuth(code).pipe(
            tap((auth: C4Auth) => {
                if (auth) {
                    //salva
                    this.auth = auth;
                    sessionStorage.setItem('auth', JSON.stringify(auth));
                    console.log('Autorizzato', auth);
                }
            }),
            map((auth: C4Auth) => {
                return auth;
            })
        );
    }

    me() {
        return this.authHttpService.me().pipe(
            tap((user: PdvUser) => {
                if (user) {
                    //salva
                    this.expireManagerUser = user;
                    sessionStorage.setItem('user', JSON.stringify(user));
                    console.log('Autorizzato Gestione Scandenze', user);
                }
            }),            
            map((user: PdvUser) => {
                return user;
            }),
            catchError( (err) => {
                console.warn(err)
                return of(null);
            })

        );
    }

    mePickPack() {
        return this.authHttpService.mePickPack().pipe(
            tap((user: PdvUser) => {
                if (user) {
                    //salva
                    this.pickPackUser = user;
                    sessionStorage.setItem('pp_user', JSON.stringify(user));
                    console.log('Autorizzato Pick Pack', user);
                }
            }),
            map((user: PdvUser) => {
                return user;
            }),
            catchError( (err) => {
                console.warn(err)
                return of(null);
            })
        );
    }

    /**
     *
     *
     * @returns {Observable<C4Auth>}
     * @memberof AuthService
     */
    refreshToken(): Observable<C4Auth> {
        let refreshToken: string = this.getRefreshTokenJWT()
        return this.authHttpService.refreshToken(refreshToken)
    }

    /**
     * Route by Role. The route is influence by type of browsing too
     * @param role
     */
    routeByRole(role: string) {
        const routes = {
            browser: {
                ROLE_DIRECTOR: '/pdv-dashboard',
                ROLE_ADMIN: '/admin'
            },
            app: {
                //ROLE_DIRECTOR: '/manager',
                ROLE_DIRECTOR: '/landing',
                ROLE_USER: '/landing'
                //ROLE_ADMIN ??
            }
        };

        if (this.device.platform === 'browser') {
            return routes.browser[role];
        } else {
            return routes.app[role];
        }
    }

    logout() {
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('pp_user');
    }


    /**
     * Test the Role of current user
     * @param expectedRole
     */
    roleIs(expectedRole: Role) {
        if (!this.expireManagerUser) {
            return false;
        }
        return (expectedRole === this.expireManagerUser.role);
    }

    userIsMe(user: PdvUser) {
        return this.expireManagerUser.username === user.username;
    }

    /**
     *
     *
     * @memberof AuthService
     */
    removeAuthFromStorage() {
        sessionStorage.removeItem('auth')
    }

}
