

import { PdvService } from 'src/app/admin/services/pdv.service';
import { ModalController } from '@ionic/angular';
import { ModalConfirmPage } from './../../../modals/modal-confirm/modal-confirm.page';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { Pdv } from '../../../../../core/models/pdv';
import { Component, OnInit, Input } from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { switchMapTo } from 'rxjs/operators';

@Component({
  selector: 'app-pnp-users',
  templateUrl: './pnp-users.component.html',
  styleUrls: ['./pnp-users.component.scss'],
})
export class PnpUsersComponent implements OnInit {

  @Input() pdv:Pdv
  constructor(private modalController: ModalController, private pdvService: PdvService) { 
    

  }

  ngOnInit() {}

  
    async toggleEnable(user, index) {
      const title = user.enabled ? 'Sospensione utente': 'Ripristino utente';
      const msg = user.enabled ? 
        `L'utente sospeso non potrà utlizzare alcuna funzione della app finchè il suo stato non sarà ripristinato.`
      : `Vuoi ripristinare questo utente?`

      const modalOptions: ModalOptions = {
          component: ModalConfirmPage,
          componentProps: {
            type:2,
              title,
              subtile:user.name,
              message: msg,
              actionText: 'Conferma',
              cancelText: 'Annulla',
              },
          showBackdrop: true,
          backdropDismiss: false,
          cssClass: ['c4-alertsaved-modal']
      }
  
      const modal = await this.modalController.create(modalOptions);
  
      modal.onDidDismiss().then((eventDetail: OverlayEventDetail) => {
        const data = eventDetail.data;
        if(data.action === 'continue'){
          console.log('Disabilita')

          this.pdv.users[index].enabled = !user.enabled;

          this.pdvService.savePnpUsers(this.pdv).subscribe(
            (res:any) => {
              console.log('Utenti aggiornati');
            },
            err => {
              console.error('Failed saveUsers',err)
            }

          )


        }else{
            //solo dismissione
        }
      })
  
      return await modal.present();
    }
}

